import {
  Card,
  DatePicker,
  Dropdown,
  Menu,
  Modal,
  Pagination,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import type { MenuProps } from "antd";
import { RouteComponentProps, useHistory } from "react-router";
import styled from "styled-components";
import {
  getInventoryFilterOptions,
  getInventories,
  requestInventoryPriceChange,
  acceptRequestToSell,
  cancelDropoff,
  getLowestPriceInventories,
  updateInventory,
  exportInventory,
  acceptInventoryPrice,
} from "../redux/actions/inventoryActions";
import { getSubLocations } from "../redux/actions/subLocationActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import { UserState } from "../redux/reducers/UserReducer";
import { ProductInventory } from "../components/Common/ProductInventoryList";
import { Product } from "../redux/reducers/ProductReducer";
import Colors from "../constants/Colors";
import { Input, Button, Image, Popconfirm, Table, Row, Col } from "antd";
import Content from "../constants/Content";
const { Search } = Input;
import { ReactComponent as SneakerMove } from "../assets/images/svg/SneakerMove.svg";
import { ReactComponent as Money } from "../assets/images/svg/Money.svg";
import { ReactComponent as Tag } from "../assets/images/svg/Tag.svg";
import { ReactComponent as SearchIcon } from "../assets/images/svg/Search.svg";
import { ReactComponent as DownArrowIcon } from "../assets/images/svg/DownArrow.svg";
import { ReactComponent as Calendar } from "../assets/images/svg/Calendar.svg";
const { RangePicker } = DatePicker;
import "./ConsignerInventory.css";
import moment from "moment";
import _ from "lodash";
import { getConsignerReport } from "../redux/actions/consignerActions";
import { ConsignerState } from "../redux/reducers/ConsignerReducer";
import { StoreState } from "../redux/reducers/StoreReducer";
import {
  // GroupOutlined,
  UnorderedListOutlined,
  EditOutlined,
  ArrowDownOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  ShopOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { ReactComponent as GroupOutlined } from "../assets/images/svg/GroupedTable.svg";
import { Link } from "@mui/material";
import { InventoryProductMobile, ReviewListingView } from "./ConsignerProducts";
import getSymbolFromCurrency from "currency-symbol-map";
import { Footer } from "antd/lib/layout/layout";
import RedesignStyling from "../constants/RedesignStyling";
import { AppState } from "../redux/reducers/AppReducer";
import Title from "antd/lib/typography/Title";
import {
  CustomSpan,
  CustomStatus,
  EnableMarketplaceBtn,
  EnableMarketplaceModal,
  InventoryListStyled,
  PriceChangeBtnMenu,
  SearchBox,
  Stat,
  StatContainer,
  StatNumber,
  TrackingCodeModal,
} from "../styles/ConsignerInventory";
import ButtonGroup from "antd/lib/button/button-group";
import { AppDispatch } from "../redux/store";
import AddedToCart from "../assets/images/AddedtoCart.png";
import EmailedProduct from "../assets/images/EmailedProduct.png";
import TrackingCodeAdded from "../assets/images/TrackingCodeAdded.png";
import { StyledButton } from "../components/Common/InventoryCardView";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${RedesignStyling.PAGE_BG};
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: ${RedesignStyling.PAGE_PADDING};
  #inventoryFilter {
    margin: 25px;
  }
  .page-title-calender {
    display: flex;
    justify-content: space-between;
    width: 100%;
    h3 {
      color: #2e2e2e;
      padding: 16px;
      font-weight: 400;
      width: 100%;
    }
    div:nth-child(2) {
      justify-content: right;
    }
  }
  @media (max-width: 768px) {
    h3 {
      padding: 0;
    }
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
    #inventoryFilter {
      margin: 0;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    color: #7f8080;
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
`;

export const MobileFooter = styled(Footer)`
  display: none;
  justify-content: center;
  align-items: center;
  order: 6;
  width: 100%;
  background: ${Colors.CONSIGNER_GREY};
  color: ${Colors.WHITE};
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const ActionBar = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    order: 3;
  }
`;

export const CustomB = styled.b`
  font-weight: 500;
  font-size: inherit;
  padding: 0 4px;
  color: ${Colors.CONSIGNER_BLUE};
  &.underlined {
    text-decoration: underline;
  }
  &.green {
    color: #16a34a;
  }
  &.red {
    color: #ef4444;
  }
`;

export const InventoryTableStyled = styled(Table)`
  width: 100%;
  padding: ${process.env.REACT_APP_TYPE === "consigner" ? "8px 16px" : "8px 0"};
  order: ${process.env.REACT_APP_TYPE === "consigner" ? "5" : "initial"};
  .ant-table {
    .ant-table-thead .ant-table-cell {
      background-color: ${process.env.REACT_APP_TYPE === "consigner"
        ? Colors.CONSIGNER_BLUE
        : Colors.ADMIN_TR_HEADER};
      text-transform: uppercase;
      color: ${process.env.REACT_APP_TYPE === "consigner" ? "#fff" : "#333"};
      padding: 10px 8px;
    }
    &.ant-table-middle {
      .ant-table-tbody .ant-table-wrapper:only-child {
        .ant-table {
          margin: 0;
        }
      }
    }
    .ant-table-tbody .ant-table-cell {
      font-size: 13px;
      .ant-table {
        margin: 0;
      }
    }
  }
`;

interface StatusSelectorProps {
  filters: any;
  setFilters: any;
  inventoryFilterOptions: any;
}

export const sizeSorter = (a, b) => {
  // Extract numbers from alphanumeric values
  const sizeA = a.option1Value?.match(/\d+/);
  const sizeB = b.option1Value?.match(/\d+/);

  // If there are no numbers in the alphanumeric values, consider them equal
  if (!sizeA && !sizeB) return 0;

  // If one of the alphanumeric values has no numbers, it should be treated as greater than the other
  if (!sizeA) return 1;
  if (!sizeB) return -1;

  // Convert extracted numbers to integers and compare
  return parseInt(sizeA[0], 10) - parseInt(sizeB[0], 10);
};

export const AddTrackingBtn = (props: any) => {
  const { record } = props;
  const [trackingCodePopup, setTrackingCodePopup] = useState(false);
  const [trackingCode, setTrackingCode] = useState("");
  const [trackingAdded, setTrackingAdded] = useState(false);
  const AddTrackingBtn = styled.a`
    text-decoration: underline;
    color: rgba(104, 95, 249, 1);
  `;

  const inventory = {
    ...record,
    trackingCode,
  };

  const dispatch = useAppDispatch();

  const onUpdate = async () => {
    dispatch(await updateInventory(record.id, inventory));
    message.success("Tracking Code Added Successfully");
    setTrackingAdded(true);
    setTrackingCodePopup(false);
    // dispatch(await getInventory(id));
  };

  return (
    <>
      {!record.trackingCode && !trackingAdded ? (
        <>
          <TrackingCodeModal
            visible={trackingCodePopup}
            title="Add Tracking Code"
            width={400}
            okText="Save"
            onCancel={() => setTrackingCodePopup(false)}
            onOk={onUpdate}
            style={{ borderRadius: "10px" }}
          >
            <p>Enter tracking code</p>
            <Input onChange={(e) => setTrackingCode(e.target.value)} />
          </TrackingCodeModal>
          <AddTrackingBtn onClick={() => setTrackingCodePopup(true)}>
            ADD TRACKING
          </AddTrackingBtn>
        </>
      ) : (
        <AddTrackingBtn>TRACK</AddTrackingBtn>
      )}
    </>
  );
};

export const PriceChangeHandler = (props: any): JSX.Element => {
  const [newPrice, setNewPrice] = useState("");
  const dispatch = useAppDispatch();
  const inventory: Inventory = props.record;
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const [filters, setFilters] = useState<any>({
    printed: "",
    status: "",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: "",
    consigner: dbUser && dbUser?.id ? dbUser?.id : "",
    sortOption: "newestUpdated",
  });
  const handlePriceChange = (value: string) => {
    if (value !== "") {
      dispatch(
        requestInventoryPriceChange(
          {
            ...inventory,
            requestedPrice: value,
          },
          filters
        )
      );
    } else {
      message.warning("Please enter a price");
    }
  };

  const acceptPriceChange = async (record: Inventory) => {
    await dispatch(acceptInventoryPrice(record));
    await dispatch(getInventories("", filters));
  };

  const handleCancel = async (id: number[]) => {
    await dispatch(cancelDropoff(id, dbUser.id));
    await dispatch(getInventories("", filters));
  };

  const menu = (
    <PriceChangeBtnMenu>
      <Menu>
        <Menu.Item>
          <Popconfirm
            title="Do you want to accept this price change request?"
            onConfirm={() => acceptPriceChange(inventory)}
            okText="Confirm"
            okButtonProps={{ type: "primary" }}
            cancelButtonProps={{ type: "text" }}
            icon={false}
          >
            <Button type="primary">Accept</Button>
          </Popconfirm>
        </Menu.Item>
        <Menu.Item>
          <Popconfirm
            title="Do you want to cancel this Dropoff?"
            onConfirm={() => handleCancel([inventory.id])}
            okText="Confirm"
            okButtonProps={{ type: "primary" }}
            cancelButtonProps={{ type: "text" }}
            icon={false}
          >
            <Button danger type="primary">
              Cancel
            </Button>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    </PriceChangeBtnMenu>
  );

  return (
    <>
      <Input
        addonAfter={
          <ArrowDownOutlined onClick={() => handlePriceChange(newPrice)} />
        }
        defaultValue={props.record.price}
        onChange={(e) => setNewPrice(e.target.value)}
        size="small"
        disabled={props.record.status === "Active" ? false : true}
        prefix={getSymbolFromCurrency(props.record.currency) || "$"}
      />
      {props.record.lowestPrice && !props.record.requestedPrice && (
        <p style={{ fontSize: "12px" }}>
          Current lowest price: {props.record.lowestPrice}
        </p>
      )}
      {props.record.requestedPrice && (
        <Dropdown overlay={menu} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            {/* <Space> */}
            Store requested price change: {props.record.requestedPrice}
            <EyeOutlined className="eye-btn" />
            {/* </Space> */}
          </a>
        </Dropdown>
      )}
    </>
  );
};

export const PayoutAmountAndCurrency = (props: any): JSX.Element => {
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  return (
    <>
      {props?.record?.payoutAmount && (
        <>
          {store.currency || "$"}
          {Number(props?.record?.payoutAmount).toFixed(2)}
        </>
      )}
    </>
  );
};

const visibleColumns: ColumnTypes[number][] = [
  {
    title: "Product",
    key: "productImage",
    dataIndex: "product.image",
    render: (_: any, record: any) => (
      <Image
        src={record.product.image}
        alt=""
        style={{ width: 100, height: "auto" }}
      ></Image>
    ),
  },
  {
    title: "",
    key: "productName",
    dataIndex: "product.title",
    render: (_: any, record: any) => (
      <>
        {`${record.product.title} `}
        <Tooltip placement="right" title={`Product ID: ${record.code}`}>
          <InfoCircleOutlined />
        </Tooltip>
      </>
    ),
  },
  {
    title: "SKU",
    key: "sku",
    dataIndex: "product.sku",
    render: (_: any, record: any) => {
      const sku = record.product.sku;
      if (sku.length > 10) {
        return sku.slice(0, 10);
      } else {
        return sku;
      }
    },
  },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    // render: (_: any, record: any) => record?.option3Value ?? null,
  },
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
    sorter: sizeSorter,
    defaultSortOrder: "ascend",
  },
  {
    title: "Condition",
    key: "option2Value",
    dataIndex: "option2Value",
    render: (_: any, record: any) => record?.option2Value ?? null,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_: any, record: any) => (
      <CustomStatus
        className={record.status === "Sold" ? "bullet green" : "bullet red"}
      >
        {record?.status === "Pending" ? "Drop off pending" : record.status}
      </CustomStatus>
    ),
  },
  {
    title: "Days Active",
    key: "acceptedOn",
    dataIndex: "acceptedOn",
    render: (_: any, record: any) => {
      const activeDate = moment(record.acceptedOn ?? record.updatedAt);
      const currentDate = moment();
      const durationDays = currentDate.diff(activeDate, "days");
      return <span>{durationDays} days</span>;
    },
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) => <PriceChangeHandler record={record} />,
  },
  {
    title: "Tracking",
    key: "",
    dataIndex: "",
    render: (_: any, record: any) =>
      record.status === "Sold" &&
      record.location === "ONLINE MARKETPLACE" && (
        <AddTrackingBtn record={record} />
      ),
  },
  {
    title: "Edit",
    dataIndex: "operation",
    render: (_: any, record: any) => (
      <Link target="_blank" href={`/productItem/${record.productId}`}>
        <Button className="edit-product-icon" icon={<EditOutlined />}></Button>
      </Link>
    ),
  },
];

const StatusSelectorDropDown = (props: StatusSelectorProps) => {
  const { filters, setFilters, inventoryFilterOptions } = props;
  const { statuses } = inventoryFilterOptions;
  const consignerStatuses = statuses?.filter(
    (status) => status.value !== "PendingSale"
  );
  consignerStatuses?.unshift({
    label: "All",
    value: "",
  });
  const [status, setStatus] = useState(filters.status);

  useEffect(() => {
    setFilters({ ...filters, status });
  }, [status]);

  const menu = (
    <Menu>
      {consignerStatuses?.map((status) => (
        <Menu.Item key={status.value} onClick={() => setStatus(status.value)}>
          {status.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button className="dropdown-btn-label" style={{ marginLeft: 8 }}>
        {status || "Filter by status"}{" "}
        <DownArrowIcon className="statuses-drop-icon-arrow" />
      </Button>
    </Dropdown>
  );
};

const LocationSelectorDropDown = (props: StatusSelectorProps) => {
  const { filters, setFilters, inventoryFilterOptions } = props;
  const { locations } = inventoryFilterOptions;

  const consignerLocation = [{ label: "All", value: "" }, ...locations];
  const [location, setLocation] = useState(filters.location);

  useEffect(() => {
    setFilters({ ...filters, location });
  }, [location]);

  const menu = (
    <Menu>
      {consignerLocation?.map((location) => (
        <Menu.Item
          key={location.value}
          onClick={() => setLocation(location.value)}
        >
          {location.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button className="dropdown-btn-label" style={{ marginLeft: 8 }}>
        {location || "Filter by location"}{" "}
        <DownArrowIcon className="statuses-drop-icon-arrow" />
      </Button>
    </Dropdown>
  );
};

interface Props {
  isMobile?: boolean;
}

const goToProductPage = (
  history: RouteComponentProps["history"],
  productInventory: ProductInventory
) => {
  history.push(`/productItem/${productInventory.product.id}`);
};

export const getProductInventories = (
  inventories: Inventory[]
): ProductInventory[] => {
  const products = inventories
    .filter((item) => item.product !== null)
    .map((item) => item.product);
  const uniqueProducts = products.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );

  return uniqueProducts.reduce(
    (allProducts: ProductInventory[], product: Product) => {
      const productInventories = inventories
        .filter((item) => item.product !== null)
        .filter((inventory) => inventory.product.id === product.id);
      return [...allProducts, { product, inventories: productInventories }];
    },
    []
  );
};

const ExpandedRowRender = (record, inventories) => {
  const expandedDataSrc = inventories.filter(
    (inventory) => inventory.productId === record.productId
  );
  return (
    <Table
      dataSource={expandedDataSrc}
      style={{ borderLeft: `4px solid ${Colors.CONSIGNER_BLUE}` }}
      columns={visibleColumns as ColumnTypes}
      rowKey="id"
      pagination={{
        pageSize: 10,
        hideOnSinglePage: true,
      }}
      showHeader={false}
    />
  );
};

export const ConsignerStats = (props: any) => {
  const { consignerReport, store, totalPrice } = props;
  const { consignerAnalytics } = consignerReport;

  const formatStatistic = (number = 0): string => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "K";
    } else {
      return number.toString();
    }
  };

  return (
    <StatContainer>
      <Stat>
        <span>
          <span>
            <Tag />
          </span>
        </span>
        <div>
          <b>Total Sales</b>
          <div>
            <StatNumber>
              {getSymbolFromCurrency(store.currency)}
              {formatStatistic(consignerAnalytics?.totalSold)}
            </StatNumber>
            <p>(before fees)</p>
          </div>
        </div>
      </Stat>
      <Stat>
        <span>
          <span>
            <SneakerMove />
          </span>
        </span>
        <div>
          <b>Total Paid</b>
          <div>
            <StatNumber>
              {getSymbolFromCurrency(store.currency)}
              {formatStatistic(consignerAnalytics?.totalPaid)}
            </StatNumber>
            <p></p>
          </div>
        </div>
      </Stat>
      <Stat>
        <span>
          <span>
            <MoneyCollectOutlined
              color="#746BF8"
              style={{ color: "#746BF8" }}
            />
          </span>
        </span>
        <div>
          <b>Total Active</b>
          <div>
            <StatNumber>
              {getSymbolFromCurrency(store.currency)}
              {totalPrice}
            </StatNumber>
            <p></p>
          </div>
        </div>
      </Stat>
      <Stat>
        <span>
          <span>
            <Money />
          </span>
        </span>
        <div>
          <b>Total Pending</b>
          <div>
            <StatNumber>
              {getSymbolFromCurrency(store.currency)}
              {formatStatistic(consignerAnalytics?.totalPending)}
            </StatNumber>
            <p>(after fees)</p>
          </div>
        </div>
      </Stat>
    </StatContainer>
  );
};

export type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const InventoryTable = (props: any): JSX.Element => {
  const {
    inventoryList,
    inventoryLoading,
    nestedView,
    columns,
    lowestPriceProducts,
  } = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [groupedData, setGroupedData] = useState<any[]>([]);
  const [columnSrc, setColumnSrc] = useState<any[]>([]);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [pageSize, setPageSize] = useState(10);
  const [newPrice, setNewPrice] = useState("");

  const groupColumns = [
    {
      title: "Product",
      dataIndex: "productImage",
      key: "product.image",
      render: (image) => (
        <Image
          src={image}
          alt="Product Photo"
          style={{ width: 100, height: "auto" }}
        />
      ),
    },
    {
      title: "",
      dataIndex: "productTitle",
      key: "product.title",
    },
    {
      title: "SKU",
      dataIndex: "productSku",
      key: "product.sku",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  useEffect(() => {
    if (
      inventoryList.length > 0 &&
      lowestPriceProducts.length > 0 &&
      !nestedView
    ) {
      setColumnSrc(columns);
      const mergedInventories = inventoryList.map((inventory) => {
        const matchingLowestPrices = lowestPriceProducts.filter(
          (item) =>
            item.product.sku === inventory.product.sku &&
            item.option1Value === inventory.option1Value &&
            item.option2Value === inventory.option2Value
        );
        const lowestPriceItem = matchingLowestPrices.reduce((lowest, item) => {
          if (!lowest || item.price < lowest.price) {
            return item;
          }
          return lowest;
        }, null);

        return {
          ...inventory,
          lowestPrice:
            matchingLowestPrices.length > 0 ? lowestPriceItem.price : null,
        };
      });

      setDataSource(
        mergedInventories.map((inventory) => ({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
        }))
      );
    } else if (nestedView) {
      const groupedData = inventoryList.reduce((groups, item) => {
        const groupId = item.productId;
        if (!groups[groupId]) {
          groups[groupId] = {
            productId: groupId,
            key: groupId,
            productTitle: item.product.title,
            productImage: item.product.image,
            productSku: item.product.sku,
            quantity: 0,
            inventories: [],
          };
        }
        groups[groupId].quantity++;
        groups[groupId].inventories.push(item);
        return groups;
      }, {});
      setDataSource(Object.values(groupedData));
      setGroupedData(Object.values(groupedData));
      setColumnSrc(groupColumns);
    } else if (
      inventoryList.length > 0 &&
      lowestPriceProducts.length === 0 &&
      !nestedView
    ) {
      setColumnSrc(columns);
      setDataSource(
        inventoryList.map((inventory) => ({
          ...inventory,
          key: inventory.id,
          currency: store.currency,
          lowestPrice: null,
        }))
      );
    } else if (!inventoryList.length) {
      setColumnSrc(columns);
      setDataSource([]);
    }
  }, [inventoryList, lowestPriceProducts, store.currency, nestedView]);

  return (
    <>
      {isMobile ? (
        <InventoryProductMobile
          inventoryList={dataSource} //  add currency
          store={store}
          isInventory={isMobile}
          handleDelete={null}
          nestedView={nestedView}
          groupedData={groupedData}
        />
      ) : (
        <InventoryListStyled
          dataSource={dataSource}
          columns={columnSrc}
          size="middle"
          pagination={{
            pageSize,
            hideOnSinglePage: true,
          }}
          expandable={{
            expandedRowRender: (record) =>
              ExpandedRowRender(
                record,
                inventoryList.map((inventory) => ({
                  ...inventory,
                  key: inventory.id,
                  currency: store.currency,
                }))
              ),
            rowExpandable: (data) => nestedView,
            expandRowByClick: true,
          }}
          loading={inventoryLoading}
        />
      )}
    </>
  );
};

const FindInventory = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    inventories,
    inventoriesLoading,
    inventoryFilterOptions,
    inventoryFilterOptionsLoading,
    lowestPriceProducts,
  }: InventoryState = useAppSelector((state) => state.InventoryReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const { consignerReport, consignerReportLoading }: ConsignerState =
    useAppSelector((state) => state.ConsignerReducer);
  const { subLocations, subLocationsLoading, updatedSubLocationLoading } =
    useAppSelector((state) => state.SubLocationReducer);
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [search, setSearch] = useState("");
  const [adjustableColumn, setAdjustableColumn] = useState<Array<any>>([]);
  const [tableNestedView, setTableNestedView] = useState(false);
  const [enableMarketplacePopup, setEnableMarketplacePopup] = useState(false);
  const [totalPrice, setTotalPrice] = useState<any>();
  const [dateRange, setDateRange] = useState<Array<any>>([
    moment().subtract(7, "days").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);

  const [filters, setFilters] = useState<any>({
    printed: "",
    status: "",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: "",
    location: "",
    consigner: dbUser && dbUser.id ? dbUser.id : "",
    sortOption: "newestUpdated",
  });

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
    dispatch(getSubLocations());
  }, []);

  useEffect(() => {
    //fetch all reports data here
    dispatch(getConsignerReport(filters, dateRange));
  }, [dateRange]);

  useEffect(() => {
    //refresh inventory list on filter or search change
    dispatch(getInventories(search, filters));
  }, [search, filters]);

  useEffect(() => {
    if (inventories.length) {
      const lowestPriceFilter = {
        consigner: dbUser && dbUser.id ? dbUser.id : "",
        productSkus: inventories.map((inventory) => inventory.product.sku),
      };
      dispatch(getLowestPriceInventories(lowestPriceFilter));
      const total = inventories
        .filter((inv) => inv.status === "Active")
        .reduce((sum, inv) => sum + +inv.price, 0);

      const formattedTotal = total.toLocaleString(); // Format the total with commas
      setTotalPrice(formattedTotal);
    }
    const tracking = inventories.some(
      (inv) => inv.location === "ONLINE MARKETPLACE" && inv.status === "Sold"
    );
    let column = [...visibleColumns];
    if (!tracking) {
      column = column.filter((co) => co.title !== "Tracking");
    }
    setAdjustableColumn(column);
  }, [search, filters, inventories]);

  /**
   * Export inventories to csv
   * Maps through Inventories and creates a csv file
   * @returns {void}
   */
  const exportInventoryToCsv = () => {
    const columnTitles = [
      "Inventory ID",
      // "Product Image",
      "Product Title",
      "SKU",
      "Option1Value",
      "Option2Value",
      "Option3Value",
      "Status",
      "Date",
      "Price",
      "Total Payout",
    ];

    inventories.sort((a, b) => a.productId - b.productId);
    const csvData = inventories.map((inventory) => [
      `"${inventory.code}"`,
      `"${inventory.product.title}"`,
      `"${inventory.product.sku}"`,
      `"${inventory.option1Value}"`,
      `"${inventory.option2Value}"`,
      inventory.option3Value && typeof inventory.option3Value !== "undefined"
        ? `"${inventory.option3Value}"`
        : "",
      `"${inventory.status}"`,
      `"${inventory.acceptedOn}"`,
      `"${inventory.price}"`,
      `"${inventory.payoutAmount}"`,
    ]);

    csvData.unshift(columnTitles);
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "inventory.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    message.success("Inventory exported successfully", 3);
  };

  if (!inventoryFilterOptions || inventoryFilterOptionsLoading)
    return (
      <PageContainer className="ConsignerInventoryPage">
        <Spin />
      </PageContainer>
    );

  return (
    <PageContainer className="ConsignerInventoryPage">
      <span className="page-title-calender">
        <Title level={3}>Inventory</Title>
        <CustomSpan>
          <span>
            <Calendar />
            <RangePicker
              onChange={(dates, dateString: any) => setDateRange(dateString)}
              defaultValue={[moment().subtract(7, "days"), moment()]}
              allowClear={true}
              suffixIcon={false}
            />
          </span>
          <></>
        </CustomSpan>
      </span>
      <ConsignerStats
        consignerReport={consignerReport}
        store={store}
        totalPrice={totalPrice}
      />
      <SearchBox>
        <div className="searchbox-statuses-filter">
          <Search
            data-testid="search"
            placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
            allowClear
            size="large"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            prefix={<SearchIcon className="product-search-icon" />}
          />
          <StatusSelectorDropDown
            filters={filters}
            setFilters={setFilters}
            inventoryFilterOptions={inventoryFilterOptions}
          />
          <LocationSelectorDropDown
            filters={filters}
            setFilters={setFilters}
            inventoryFilterOptions={inventoryFilterOptions}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <StyledButton
            type="ghost"
            style={{
              padding: "24.5px 16px",
              display: isMobile ? "none" : "flex",
            }}
            onClick={() => dispatch(exportInventory(search, filters))}
          >
            {Content.FIND_INVENTORY_ACTION_BUTTON_TWO}
          </StyledButton>
          <span className="hamburger-btns-span">
            <Button
              icon={<UnorderedListOutlined />}
              type={tableNestedView ? "default" : "primary"}
              onClick={() => setTableNestedView(false)}
            ></Button>
            <Button
              icon={<GroupOutlined />}
              type={tableNestedView ? "primary" : "default"}
              onClick={() => setTableNestedView(true)}
            ></Button>
          </span>
        </div>
      </SearchBox>
      <InventoryTable
        inventoryList={inventories}
        inventoryLoading={inventoriesLoading}
        nestedView={tableNestedView}
        columns={adjustableColumn}
        lowestPriceProducts={lowestPriceProducts}
      />
      {store.onlineMarketplace && (
        <EnableMarketplaceBtn onClick={() => setEnableMarketplacePopup(true)}>
          <ShopOutlined />
          Enable Marketplace
        </EnableMarketplaceBtn>
      )}
      <EnableMarketplaceModal
        visible={enableMarketplacePopup}
        footer={false}
        width={900}
        onCancel={() => setEnableMarketplacePopup(false)}
        style={{ borderRadius: "10px" }}
      >
        <div className="modal-content">
          <div className="center-text">
            <h3>How it works?</h3>
            <p>
              Add your stock online and after the item sells you will be updated
              to ship the item.
            </p>
          </div>
          <div className="card-container">
            <div className="card-container-item">
              <div style={{ textAlign: "center" }}>
                <img className="card-item" src={AddedToCart} />
              </div>
              <h3>Step 1</h3>
              <p>Add items - Assign it to online marketplace</p>
            </div>
            <div className="card-container-item">
              <div style={{ textAlign: "center" }}>
                <img className="card-item" src={EmailedProduct} />
              </div>
              <h3>Step 2</h3>
              <p>After sale - We will email you address and ready to ship</p>
            </div>
            <div className="card-container-item">
              <div style={{ textAlign: "center" }}>
                <img className="card-item" src={TrackingCodeAdded} />
              </div>
              <h3>Step 3</h3>
              <p>
                Ship out or drop off- Add your tracking code for us to track or
                drop off within 1 day
              </p>
            </div>
          </div>
          <div className="button-container">
            <Button onClick={() => setEnableMarketplacePopup(false)}>
              Confirm
            </Button>{" "}
          </div>
        </div>
      </EnableMarketplaceModal>
    </PageContainer>
  );
};

export default FindInventory;
